<template>
  <div class="container">

    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-rx">
        <div class="data ctop">
          <h3>连码</h3>
          <ul class="datatop">
            <li @click="selecteLianMa(i,ele)" :class="{selected:currentIndex==i}" style="width:16.66%;" v-for="(ele,i) in rowsData[0].arr" :key="ele.label">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
            </li>

          </ul>
        </div>
        <!-- 水果 -->
        <div class="data">
          <ul>
            <li :class="item.selected ? 'selected' : ''" @click="setCurrentSelected(item)" v-for="item in numArr" :key="item.number">
              <ol class="td_name">
                <div :class="'blueball b'+item.label"></div>
              </ol>

            </li>

            <!-- 这是水果 -->
            <!-- <li>
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b02">&nbsp;&nbsp;</p>
                </li>
              </ol>

            </li> -->

          </ul>
        </div>
        <!-- --- -->
      </div>
      <div class="player listmain tuoduopeng ">
        <div class="data">
          <h3>注单号码<span>共 <em class="red">{{totalNum}}</em> 种组合 </span></h3>

          <!-- <div class="dantuo-li">
            
          </div> -->
          <div class="dantuo-sc  red">
            <div>
              <span v-for="(item,index) in zuheData" :key="index">[{{item.join(',')}}]</span>
            </div>
          </div>
        </div>
      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
/**
 * 求：组合C(m, n)，m为上标，n为下标。m选n的所有项
 * m {必传} 原始数据
 * n {必传} 当前项还需元素的个数
 * currentIndex 当前索引
 * choseArr 当前项的部分元素集合（不是完整项，是生成完整项的一个中间状态）
 * result 所有项的结果结合
 */
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}
export default {
  name: "klsflianma",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lma',
      totalNum: 0,
      zuheData: [],
      labelArr: [
        "任选二",
        "任选三",
        "任选四",
        "任选五",
        "选二连组",
        "选三前组",
      ],
      currentIndex: 0,
      bzArr: [2, 3, 4, 5, 2, 3],
      bzIndex: 2,
      numArr: [
        { label: "01", selected: false, number: 1 },
        { label: "02", selected: false, number: 2 },
        { label: "03", selected: false, number: 3 },
        { label: "04", selected: false, number: 4 },
        { label: "05", selected: false, number: 5 },
        { label: "06", selected: false, number: 6 },
        { label: "07", selected: false, number: 7 },
        { label: "08", selected: false, number: 8 },
        { label: "09", selected: false, number: 9 },
        { label: "10", selected: false, number: 10 },
        { label: "11", selected: false, number: 11 },
        { label: "12", selected: false, number: 12 },
        { label: "13", selected: false, number: 13 },
        { label: "14", selected: false, number: 14 },
        { label: "15", selected: false, number: 15 },
        { label: "16", selected: false, number: 16 },
        { label: "17", selected: false, number: 17 },
        { label: "18", selected: false, number: 18 },
        { label: "19", selected: false, number: 19 },
        { label: "20", selected: false, number: 20 },
      ],
      rowsData: [{ title: "连码", arr: [] }],
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    numArr: {
      immediate: true,
      deep: true,
      handler(numarr) {
        this.watchData();
      },
    },
    amount(val) {
      this.watchData();
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resArr) {
        let arr = this.filterData(resArr, 1761, 1766, this.labelArr);
        arr.forEach((item, index) => {
          item.bzNum = this.bzArr[index];
        });

        this.rowsData[0].arr = arr;
      },
    },
  },
  created() {},
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    watchData() {
      let arr = this.numArr.filter((item) => item.selected);
      let selArr = [];
      let len = arr.length;
      this.totalNum = 0;
      this.zuheData = [];
      if (len < this.bzIndex) {
        this.totalNum = 0;
        this.zuheData = [];
        this.$store.commit("setXZList", []);
      } else {
        arr.forEach((item) => {
          selArr.push(item.label);
        });
        let item = this.rowsData[0].arr[this.currentIndex];
        if (len == this.bzIndex) {
          this.totalNum = 1;
          this.zuheData = [selArr];
          let obj = {
            label: selArr.join(","),
            num: selArr.join(","),
            title: item.label,
            id: item.ResultID,
            money: this.amount,
            Odds: item.Odds,
          };
          this.$store.commit("setXZList", [obj]);
        } else {
          let xiazhu = [];
          let cArr = cmn(selArr, this.bzIndex);
          cArr.forEach((ele) => {
            this.totalNum++;
            this.zuheData.push(ele);
            let obj = {
              label: ele.join(","),
              num: ele.join(","),
              title: item.label,
              id: item.ResultID,
              money: this.amount,
              Odds: item.Odds,
            };
            xiazhu.push(obj);
          });
          this.$store.commit("setXZList", xiazhu);
        }
      }
    },
  },
};
</script>
<style  scoped>
.listmain > .data > ul > li .td_name {
  width: 100%;
}
.listmain .data ul li {
  width: 10%;
}
.listmain div.ctop .datatop li ol {
  width: auto !important;
  max-width: none !important;
  display: block;
  border-right: none !important;
}
</style>